import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/MainView.vue')
    },
    {
        path: '/requests',
        name: 'requests',
        component: () => import(/* webpackChunkName: "requests" */ '../views/RequestsView.vue')
    },
    {
        path: '/request/:id',
        name: 'requestDetail',
        component: () => import(/* webpackChunkName: "requests" */ '../views/RequestDetailView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
