<template>
  <div class="SidebarSection">
    <div class="list-group">
      <router-link to="/" class="list-group-item" aria-current="true">
        Создать запрос
      </router-link>
      <router-link to="/requests" class="list-group-item" aria-current="true">
        Список запросов
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import '@/components/SidebarSection/SidebarSection.css';

export default defineComponent({
  name: 'SidebarSection',
});
</script>
